.total_card img {
    margin-bottom: 10px;
}

.total_card p {
    color: #848484;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 5px;
}

.total_card h4 {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0;
}
.date_filter button#dropdown-basic {
    border-radius: 7px;
    background: #F4F7FE;
    padding: 10px 13px 9px 12px;
    border: transparent;
    color: #A3AED0;
    font-size: 14px;
}

.date_filter  .dropdown-menu {
text-align: center;
}
.date_filter  .dropdown-menu {
    min-width: 89px;
}
.stats_icon {
    display: flex;
    width: 33px;
    height: 33px;
    padding: 5px 4px 4px 5px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #F4F7FE;
}
.graph_header h6 {
    color: #2B3674;
    font-size: 20px;
    font-weight: 700;
}
/* line graph 1 */
.spline_graph {
    display: flex;
    gap: 10px;
    white-space: nowrap;
}

.spline_content h3 {
    color: #2B3674;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px; /* 123.529% */
    letter-spacing: -0.68px;
}

.total_Spend {
    display: flex;
    justify-content: space-between;
}

.total_Spend p:first-child {
    color: #A3AED0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
}

.total_Spend p:last-child {
    color: #11A881;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.24px;
}
p.on_trach {
    color: #11A881;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.32px;
}
.spline_graph{
    margin-top: 28px;
}
.pie_content ul {display: flex;justify-content: space-between;}

.pie_content ul li {width: 100%;text-align: center;list-style: none;}

.pie_content ul li span.yellow {
    background: #F2C94C;
}
.pie_content ul li span {
    width: 8px;
    height: 8px;
    background: #FF65C3;
    display: inline-block;
    margin-right: 10px;
    border-radius: 50%;
}

.pie_content li p {
    color: #A3AED0;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.24px;
    margin-bottom: 5px;
}

.pie_content h3 {
    color: #2B3674;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 166.667% */
    letter-spacing: -0.36px;
}

.pie_content ul {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 18px 40px 0px rgba(112, 144, 176, 0.12);
    padding: 22px;
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
}
/* line graph 1 */
