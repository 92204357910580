.data_table thead tr  {
    border-radius: 8px;
     border-bottom: 1px solid #EDEDED;
    background: #FFF; 
    overflow:hidden;
    box-shadow:0px 0px 1px;
}

.data_table thead tr th:first-child {
    border-top-left-radius:8px;
    
}
.data_table thead tr th:last-child {
    border-top-right-radius:8px;
}
.data_table thead tr th:last-child::after {
    display: none;
}
.data_table thead th::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 14px;
    background: #737373;
    right: 0;
    top: 33px;
}

.data_table thead th {
    position: relative;
    color: #737373;
    font-size: 16px;
    font-weight: 400;
    padding: 26px 15px;
}

.data_table  tr td {
    padding: 20px 15px;
    color: #737373;
font-size: 14px;
font-style: normal;
}

.data_table tbody> tr:nth-child(even) td {
    background: #F6F6F6;
}

.view_more {
    color: #FF65C3;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
    cursor: pointer;
    margin-bottom: 0;
}
.data_table {
    margin-bottom: 0px;
}
table.data_table img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
}
.cursor{
    cursor: pointer;
}
.data_table tbody tr {
    background: #fff;
} 
.data_table th div {
    white-space: nowrap;
}
.data_table tbody tr:nth-child(even) td {
    background: #FFF0F9;
}
.data_table tbody tr:last-child{
    border-bottom: 1px solid #ddd;
}

/* .data_table thead tr th:last-child span {
    display: none !important;
} */