.booking_header {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 1.5em;
}

.booking_header h3 {
    margin: 0px;
    font-style: normal;
font-weight: 400;
font-size: 24px;
}

span.status_badge {
    border-radius: 10px;
    border: 1px solid #FF65C3;
    background: rgba(204, 81, 156, 0.10);
    display: inline-block;
    height: 37px;
    line-height: 37px;
    padding: 0px 15px;
    font-size: 16px;
    color: #ff65c3;
}

.booking_details ul {
    padding: 0;
    display: flex;
    gap: 2em;
}
.booking_detrails_wrapper {
    background: #FFF;
    padding: 30px;
}

.booking_details ul li {
    list-style: none;
    min-width: 200px;
}

.booking_details ul {
    padding: 0;
    display: flex;
    column-gap: 3em;
    flex-wrap: wrap;
}

.booking_detrails_wrapper {
    background: #FFF;
    padding: 30px;
}

.booking_details ul li strong {
    display: inline-block;
    margin-bottom: 8px;
    display: inline-block;
    margin-bottom: 8px;
}

.booking_details h5 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 18px;
}
.round_image img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.booking_details p {
    color: #737373;
    font-family: SF Pro Display;
    font-size: 18px;
}

.booking_details strong {
    font-size: 18px;
}