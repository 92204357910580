.driver_info_list li{
    list-style: none;
}
.wallet_info.driver_info_list li {
    width: unset;
}

.wallet_info.driver_info_list ul {
    justify-content: flex-start;
}

.wallet_info.driver_info_list li img {
    width: 110px;
    height: 133px;
    flex: 0 0 110px;
    object-fit: cover;
}

.wallet_total h2 {
    color: #000;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
}

.wallet_total p {
    color: #737373;
    font-family: SF Pro Display;
    font-size: 18px;
    font-weight: 500;
}
.wallet_header .lgo_cmn_btn {
    height: 30px;
    font-size: 14px;
    min-width: 80px;
    padding-bottom: 2px;
}
.wallet_header {
    border-radius: 25px;
    border: 1px solid #EDEDED;
    background: #FFF;
    padding: 25px;
}

.wallet_total {
    display: flex;
    justify-content: flex-end;
}
.wallet_total h2,.wallet_total p {
    min-width: 175px;
    text-align: center;
}