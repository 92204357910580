/* .App {
  text-align: center;
} */
@font-face {
  font-family: "SF Pro Display";
  src: url("./Fonts/SFProDisplay-ThinItalic.woff2") format("woff2"),
    url("./Fonts/SFProDisplay-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./Fonts/SFProDisplay-SemiboldItalic.woff2") format("woff2"),
    url("./Fonts/SFProDisplay-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./Fonts/SFProDisplay-UltralightItalic.woff2") format("woff2"),
    url("./Fonts/SFProDisplay-UltralightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./Fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("./Fonts/SFProDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./Fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("./Fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./Fonts/SFProDisplay-BlackItalic.woff2") format("woff2"),
    url("./Fonts/SFProDisplay-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./Fonts/SFProDisplay-HeavyItalic.woff2") format("woff2"),
    url("./Fonts/SFProDisplay-HeavyItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./Fonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("./Fonts/SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./Fonts/SFProDisplay-LightItalic.woff2") format("woff2"),
    url("./Fonts/SFProDisplay-LightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body,
p,
strong,
span,
h1,
h2,
h3,
h4,
h5,
h6,
li,
a,
td,
th,
label,
input,
.modal-title {
  font-family: "SF Pro Display";
}
/* filters */
.filters ul {
  display: flex;
  padding: 0px;
  gap: 15px;
}

.filters li {
  list-style: none;
  width: 100%;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.filters li input,
.filters li select {
  height: 48px;
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 0px 15px;
  min-width: 200px;
  color: #737373;
  font-size: 14px;
  width: 100%;
}

li.search {
  position: relative;
}

li.search svg {
  position: absolute;
  top: 18px;
  right: 12px;
}

/* modal css */
.modal-header .btn-close {
  border: 2px solid #ff65c3;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 11px;
  color: #ff65c3;
  --bs-btn-close-bg: url(data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FF65C3%27><path d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/></svg>);
  font-weight: 700;
  opacity: 1;
  margin: unset !important;
}
.modal-title.h4 {
  color: #1e1e1e;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
}
div.modal-content {
  border-radius: 20px;
}
.modal-content .loader_outer {
  border-radius: 18px;
}
.modal_body.modal-body {
  border: 24px;
  padding: 24px;
}
.modal_header.modal-header {
  padding: 24px;
}
.confirmodal .modal_header.modal-header {
  display: none;
}
.confirmodal .modal_body.modal-body {
  padding: 44px 24px;
}

.confirmodal h4 {
  color: #1e1e1e;
  font-family: 'SF Pro Display';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  text-align: center;
  margin-bottom: 30px;
}
.custom_paginations {
  display: flex;
  justify-content: space-between;
}
.custom_paginations {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 27px 33px;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
li.previous a,
li.next a {
  color: #ff65c3;
  text-decoration: unset;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}
ul.pagination {
  gap: 30px;
  margin: 0;
  align-items: center;
}
li.active {
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  border-radius: 12px;
  background: #ff65c3;
  color: #fff;
}

text.highcharts-credits {
  display: none;
}
table.table>:not(caption)>*>*{
  background-color: unset;
}

.loader_outer {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
  background: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  z-index: 999;
}

.loader_outer img {
  width: 180px;
}

table.data_table .loader_image {
  width: 250px;
  height: 250px;
}
div.modal-body{
  position: unset;
}
.modal_body .login_wrapper .form-control {
  width: 100%;
  min-width: unset;
}
.driver_request_details ul.three_column li {
  width: 30%;
}
.faq_width{
  min-width: 80px;
}.jodit-workplace{
  height: 350px !important;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  width: 100%;
}
