.driver_info_card {
    border-radius: 10px;
    border: 1px solid #EEE;
    background: #FFF;
    padding: 24px;
}


.driver_info_card li {
    list-style: none;

}

.driver_info_list li {
    width: 100%;
}

.drive_info {
    display: flex;
    gap: 10px;
}

.drive_info img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

img.verify_image {
    width: 28px;
    margin-left: 9px;
}

.driver_picture {
    position: relative;
    flex: 0 0 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 20%);
    width: 50px;
}

img.verify {
    position: absolute;
    left: 0;
    width: 15px;
    height: 15px;
}

.driver_info_list ul {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.driver_info_list p {
    color: #737373;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
}

.driver_filter select {
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    min-width: 144px;
    height: 48px;
    padding: 0px 6px;
    color: #7F7F7F;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}
.car_image img{
    height: 300px;
    width: 100%;
    object-fit: cover;
}

.filters.short_length li {
    max-width: 250px;
}

.drive_info h6 {
    color: #000;
    font-size: 20px;
    font-style: normal;
}

.driver_info_list strong {
    font-weight: 500;
}

.car_img:hover .slick-prev,
.car_img:hover .slick-next {
    opacity: 1;
    visibility: visible;
    transition: ease all .3s;

}

.car_img .slick-prev,
.car_img .slick-next {
    opacity: 0;
    visibility: hidden;
    transition: ease all .3s;
}

.car_img img {
    object-fit: contain;
    height: 290px;
    width: 100%;
}

.car_img .slick-prev:before,
.car_img .slick-next:before {
    color: #000;
    font-size: 30px;
    z-index: 2;
}

.car_img .slick-prev:before {
    content: "‹";
}

.car_img .slick-next {
    right: -6px;
}

.car_img .slick-prev {
    left: -6px;
    z-index: 2;
}

.car_img .slick-next:before {
    content: "›";
}

.car_details ul {
    margin-bottom: 5em;
}

.car_details ul:last-child {
    margin: 0;
}

.car_img {
    background: #D9D9D9;
    padding: 15px;
    text-align: center;
    height: 316px;
    object-fit: cover;

}

.driver_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-start
}

.driver_header span.badge {
    opacity: 1;
    color: #2CD61D;
    border-radius: 6px;
    background: rgba(101, 255, 126, 0.20);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 10px;
    padding-left: 30px;
    position: relative;
}

.driver_header span.badge::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #2CD61D;
    border-radius: 50%;
    left: 12px;
    top: 12px;
}

.driver_header span.badge.online {
    color: #FF65C3;
    background: rgba(255, 101, 195, 0.10);
}

.driver_header span.badge.online::before {
    background-color: #FF65C3;

}

select:focus-visible {
    outline: unset;
}

.driver_filter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 26px;
}

.rating {
    white-space: nowrap;
    gap: 10px;
}

/* custom toggle */
.switch input {
    display: none;
}

.switch {
    display: inline-block;
    width: 60px;
    /*=w*/
    height: 30px;
    /*=h*/
    margin: 4px;
    /* transform: translateY(50%); */
    position: relative;
}

.slider_switch {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    /* box-shadow: 0 0 0 2px #777, 0 0 4px #777; */
    outline: 2px solid #777;
    cursor: pointer;
    border: 4px solid transparent;
    overflow: hidden;
    transition: 0.2s;
}

.slider_switch:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #777;
    border-radius: 30px;
    transform: translateX(-30px);
    /*translateX(-(w-h))*/
    transition: 0.2s;
    left: 0;
}

input:checked+.slider_switch:before {
    transform: translateX(30px);
    /*translateX(w-h)*/
    background-color: #FF65C3;
}

input:checked+.slider_switch {
    outline: 2px solid #FF65C3;
}

.enable_driver span.d-block {
    color: #FF65C3;
    font-family: SF Pro Display;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
    position: absolute;
    top: -8px;
    text-align: center;
    width: 100%;
}

.enable_driver {
    position: relative;
    padding-top: 14px;
}