.Login_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
}
.login_img_section {
    background: rgba(225, 226, 227, 0.90);
}
.login_form h2 {
    color: #252733;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
}

.login_form p, .login_form p a {
    color: #9FA2B4;
    font-size: 14px;
    text-decoration: none;

}
.login_form img {
    width: 156px;
    margin-left: auto;
    height: 156px;
}
p.existing_account a {
    color: #000;
    text-decoration: none;
}
.login_wrapper label, .form_wrapper label{
    color: #101010;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 10px 0px;
}

.login_wrapper .form-control,.form_wrapper .form-control,.form_wrapper .form-select {
    min-height: 42px;
    border: 1px solid #000;
}
@media(min-width:1699px){
    .login_wrapper .form-control {
        min-width: 500px;
    } 
}