.booking_filter {
    display: flex;
    column-gap: 10px;
    padding-bottom: 10px;
}

.booking_filter * {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #EDEDED;
    padding: 0px 15px;
    color: #737373;
    font-size: 14px;
}
    ul.trip_haeder {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    ul.trip_haeder li:last-child {
        border-radius: 10px;
        background: rgba(255, 101, 195, 0.10);
        padding: 4px 10px;
        list-style: none;
        color: #FF65C3;
        position: relative;
        padding-left: 25px;
        font-family: Poppins;
        font-size: 16px;
    }
    
    ul {
      padding-left: 0; /* Remove left padding/margin, which affects the marker */
    }
    
    ul.trip_haeder li:last-child::after {
        position: absolute;
        content: "";
        left: 0;
        width: 5px;
        height: 5px;
        background: #FF65C3;
        border-radius: 50%;
        left: 10px;
        top: 12px;
    }
    
    ul.trip_haeder li:first-child {
        color: #000;
        font-family: Poppins;
        font-size: 20px;
        list-style: none;
    }
    ul.tarchLocation::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 50px;
        border: 1px dashed #ff65c3;
        left: 9px;
        top: 15px;
    }
    ul.tarchLocation {
        margin: 0;
        padding-left: 2em;
        position: relative;
    }
    
    ul.tarchLocation li {
        list-style: none;
        padding: 10px 0px;
        position: relative;
    }
    
    ul.tarchLocation li:first-child {
        border-bottom: 1px solid #ddd;
    }
    ul.tarchLocation svg {
        position: absolute;
        left: -34px;
    
    }
    .tracking_map img.img-fluid {
        width: 100%;
        height: calc(100vh - 200px);
        object-fit: cover;
    }

.tracking_table thead {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: #fff;
    /* box-shadow: 0px 0px 1px 1px #ddd; */
    padding: 10px;
}
.tracking_table th:first-child {
    border-radius: 6px 0 0 0;
    -moz-border-radius: 6px 0 0 0;
    -webkit-border-radius: 6px 0 0 0;
}

.tracking_table th:last-child {
    border-radius: 0 6px 0 0;
    -moz-border-radius: 0 6px 0 0;
    -webkit-border-radius: 0 6px 0 0;
}

.tracking_table thead th,.tracking_table td {
    padding: 15px;
    color: #393939;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
}
p.sub_title {
    color: #767676;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    margin-bottom: 6px;
}
.tracking_table {
    position: absolute;
    width: 100%;
    z-index: 2;
    bottom: 0px;
}
.tracking_table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 15px;
     
}
.tracking_table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 15px;
     
}