.driver_request_details {
    display: flex;
    gap: 3em;
}

.driver_request_details ul li {
    list-style: none;
    width: 100%;
}

.detail_title{
    color: #1E1E1E;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.36px;
margin-bottom: 16px;
}
.driver_request_details ul {
    display: flex;
}

.driver_request_details ul li strong {
    color: #1E1E1E;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
}

.driver_request_details ul li h3 {
    color: #737373;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
}

.driver_image img{
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
}
.driver_image {
    width: 150px;
    height: 170px;
    flex: 0 0 150px;
    object-fit: cover;
}
.driver_request_details ul {
    display: flex;
}



ul.cardoc_list li {
    list-style: none;
}

ul.cardoc_list img {
    border-radius: 10px;
    width: 110px;
    height: 110px;
    margin-bottom: 10px;
    cursor: pointer;
    object-fit: cover;
}

ul.cardoc_list p {
    color: #737373;
}

ul.cardoc_list {
    display: flex;
    margin: 0;
    gap: 20px;
}
.modal_body.modal-body hr{
    margin: 23px 0px;
}
.rider_img {
    display: flex;
    align-items: center;
    gap: 11px;
}

.rider_img img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    flex: 0 0 40px;
}
.car_image_view {
    display: flex;
    gap: 15px;
}

.car_image_view img {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    border-radius: 50%;
}