.nav-tabs .nav-link.active {
    color: #FF65C3;
    background: transparent;
    border: transparent;
    border-bottom: 2px solid #FF65C3;
}

.nav-tabs .nav-link {
    color: #8B8B8B;
}
.cancellation_navs select {
    height: 48px;
    border-radius: 5px;
    border: 1px solid #ededed;
    padding: 0px 15px;
    min-width: 200px;
    color: #737373;
    font-size: 14px;
    width: 200px;
}