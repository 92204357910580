.lgo_cmn_btn {
  border: transparent;
  border-radius: 8px;
  height: 48px;
  font-size: 16px;
  color:#fff;
  min-width: 130px;
  font-weight: 500;
line-height: normal;
letter-spacing: 0.32px;
}
.theme_light {
  background: #ff65c3;
  border: 2px solid #ff65c3;
}
.theme_dark {
  background: #101010;
  border: 2px solid #101010;
}
.outline_btn {
  border-radius: 7px;
  border: 2px solid #ff65c3;
color: #ff65c3;
background-color: #fff;
padding: 0px 20px;
}
