/* header starts here */
.search_form {
    border-radius: 5px;
    background: #222;
    height: 46px;
    width: 100%;
    position: relative;
}

.search_form input {
    background: transparent;
    border: transparent;
    height: 46px;
    width: calc(100% - 20px);
}
.search_form svg{
    position: absolute;
    right: 10px;
    top: 16px;
}
.client_name {
    color: #FFF;
    font-family: 'SF Pro Display';
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.52px;
}

.user_dropdown.dropdown button {
    width: 100%;
    display: flex;
    background-color: transparent;
    border: transparent;
    box-shadow: unset;
    align-items: center;
    gap: 11px;
}
li.log_out {
    padding: 20px;
    font-size: 18px;
    color: #fff;
    gap: 15px;
    display: flex;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    left: 0;
    width: 100%;
    padding-left: 42px;
}
.user_dropdown.dropdown button::after {
    display: none;
}

.search_bar {
    display: flex;
    gap: 64px;
    align-items: center;
}

.user_dropdown.dropdown {
    min-width: 200px;
}

nav.ldo_bg.navbar.navbar-expand-lg.navbar-light {
    position: fixed;
    top: 0;
    width: calc(100% - 276px);
    right: 0;
    padding-right: 38px;
    padding-left: 20px;
    height: 100px;
    z-index: 99;
    background-color: #222;
}
main {
    min-height:calc(100vh - 125px);
    margin-left: 276px;
    margin-top: 100px;
    background: #F5F5F6;
    padding: 25px 20px;
}
.cmn_heading {
    color: #000;
    font-family: 'SF Pro Display';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.96px;
    margin-bottom: 0.8em;
}
.log_image img {
    height: 80px;
}
.user_dropdown .dropdown-toggle:active {
    background: transparent;
}
.user_dropdown .dropdown-toggle:active {
    background: transparent;
}

.user_dropdown .dropdown-menu a {
    font-weight: 500;
    color: #000;
}
.user_dropdown .dropdown-menu a:active{
    background: transparent ;
}
.log_image {
    padding-top: 5px;
    text-align: center; 
    /* height: 100px; */
    margin-bottom: 20px;
}
.navbar-collapse {
    justify-content: space-between;
    align-items: center;
}
div#responsive-navbar-nav{
    gap: 65px;
}
/* header ends here */
/* Sidebar stats here */
.side_bar {
    position: fixed;
    width: 276px;
    top: 0;
    height: 100%;
    background-color: #222;
}

.side_bar ul {
    padding: 0px 30px;
}

.side_bar ul li {
    list-style: none;
}

.side_bar li a {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    padding: 15px;
    font-size: 16px;
}

a.active {
    border-radius: 8px;
    background: #FF65C3;
}
/* Sidebar ends here */
@media(max-width:1399px){
    .side_bar li a{
        padding: 12px;
    }
}