.reports_wrapper ul li {
    list-style: none;
    display: flex;
    gap: 15px;
    border-bottom: 1px solid #EDEDED;
    padding: 15px 24px;
    align-items: center;
}

.reports_wrapper ul li img {
    flex: 0 0 50px;
    height: 50px;
}
.reports_wrapper ul li:last-child{
    border-bottom: unset;
}
.reports_wrapper ul li h4 {
    color: #434343;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom:5px;
}
.reports_wrapper ul li p {
color: #8A8A8A;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.reports_wrapper {
    background: #fff;
    border-radius: 10px;
}

.reports_wrapper h2 {
    color: #434343;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px 24px;
    margin: 0px;
}